<template>
    <v-navigation-drawer width="100%" style="height: 93vh;">
      <v-toolbar dense flat>
        <v-toolbar-title class="pl-0">{{getTrackingTrack.user.displayName}}</v-toolbar-title>
        <v-btn style="position: absolute; right: 1rem;" icon @click="onCloseClicked">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="grey--text" style="padding-left: 54px;">{{getTrackingTrack.user.username}}</div>

      <v-skeleton-loader
        :loading="getTrackingIsLoading"
        :transition="transition"
        height="400px"
        type="list-item-avatar-two-line"
      >
        <v-card flat class="mx-auto flex-column scroll">
          <v-card-text>
            <v-row class="mx-0 mb-4 flex-column">
              <div class="mb-2 grey--text">Estado</div>
              <div>
                <v-chip outlined :color="getStatusColor(getTrackingTrack)">{{getStatus(getTrackingTrack)}}</v-chip>
              </div>
            </v-row>
            <v-row class="mx-0 mb-4 flex-column">
              <div class="mb-2 dark-grey--text">Última actividad</div>
              <div class="black--text">{{dateFromNow(getTrackingTrack.trackedAt)}}</div>
            </v-row>
            <v-row class="mx-0 mb-4 flex-column">
              <div class="mb-2 dark-grey--text">Correo electrónico</div>
              <div class="black--text">{{getTrackingTrack.user.email}}</div>
            </v-row>
            <v-row class="mx-0 mb-4 flex-column">
              <div class="mb-2 dark-grey--text">Celular</div>
              <div class="black--text">{{getTrackingTrack.user.mobilePhone}}</div>
            </v-row>
            <v-row class="mx-0 mb-4 flex-column">
              <div class="mb-2 dark-grey--text">Grupos</div>
              <div
                class="black--text mb-2"
                v-for="(group, index) in getTrackingTrack.user.groups"
                :key="index"
              >
              {{group.name}}
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-skeleton-loader>
    </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex'
import { dateFromNow } from '@/mixins'
import { TRACKING_REMOVE_SELECTED_TRACK } from '@/store/mutations.type'

export default {
  name: 'STrackUserSidebar',
  props: {},
  data() {
    return {
      dialog: false,
      img: null,
      transition: 'fade-transition'
    }
  },
  computed: {
    ...mapGetters(['getTrackingIsLoading', 'getTrackingTrack'])
  },
  components: {},
  methods: {
    onMagnify(field) {
      this.dialog = true
      this.img = field.value
    },
    getStatus(track) {
      if (track.isActive) {
        return 'Activo'
      } else {
        return 'Inactivo'
      }
    },
    getStatusColor(track) {
      if (track.isActive) {
        return 'secondary'
      } else {
        return 'grey'
      }
    },
    onCloseClicked() {
      this.$store.commit(TRACKING_REMOVE_SELECTED_TRACK)
    }
  },
  mixins: [dateFromNow]
}
</script>
